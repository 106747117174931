import React from 'react'
import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import Node from '../assets/node.png';
import Tailwind from '../assets/tailwind.png';
import cSharpImg from '../assets/csharp-original.svg';
import nextJSImg from '../assets/nextjs-original-wordmark.svg';


const Skills = () => {
  return (
    <div name='skills' className="w-full h-screen bg-[#F7FBFC] text-gray-700">
        {/* Container */}
        <div className="max-w-[1000px] 7xl:max-w-[2500px] mx-auto p-4 flex flex-col justify-center w-full h-full">
            <div>
                <p className="text-4xl 7xl:text-8xl font-bold inline border-b-4 border-[#5651e5] ">Skills</p>
                <p className="py-4 7xl:text-5xl 7xl:py-8">// Some technologies that I have worked with</p>
            </div>

            <div className="w-full 7xl:text-4xl grid grid-cols-2 sm:grid-cols-3 gap-4 7xl:gap-8 text-center py-8 text-sm">
                <div className="hover:scale-110 duration-500">
                    <img className="w-[60px] 7xl:w-60 mx-auto 7xl:my-5" src={ReactImg} alt="React Icon" />
                    <p>React</p>
                </div>
                <div className="hover:scale-110 duration-500">
                    <img className="w-[60px] 7xl:w-60 mx-auto 7xl:my-5" src={HTML} alt="HTML Icon" />
                    <p>HTML</p>
                </div>
                <div className="hover:scale-110 duration-500">
                    <img className="w-[60px] 7xl:w-60 mx-auto 7xl:my-5" src={CSS} alt="CSS Icon" />
                    <p>CSS</p>
                </div>
                <div className="hover:scale-110 duration-500">
                    <img className="w-[60px] 7xl:w-60 mx-auto 7xl:my-5" src={JavaScript} alt="JavaScript Icon" />
                    <p>JavaScript</p>
                </div>
                <div className="hover:scale-110 duration-500">
                    <img className="w-[60px] 7xl:w-60 mx-auto 7xl:my-5" src={cSharpImg} alt="JavaScript Icon" />
                    <p>C#</p>
                </div>
                <div className="hover:scale-110 duration-500">
                    <img className="w-[60px] 7xl:w-60 mx-auto 7xl:my-5" src={Node} alt="Node JS Icon" />
                    <p>Node JS</p>
                </div>
                <div className="hover:scale-110 duration-500">
                    <img className="w-[60px] 7xl:w-60 mx-auto 7xl:my-5" src={Tailwind} alt="Tailwind Icon" />
                    <p>Tailwind</p>
                </div>
                <div className="hover:scale-110 duration-500">
                    <img className="w-[60px] 7xl:w-60 mx-auto 7xl:my-5" src={nextJSImg} alt="Tailwind Icon" />
                    <p>Next.js</p>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Skills