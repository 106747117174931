import React, { useState, useEffect } from 'react';
import { FaBars, FaGithub, FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { Link } from 'react-scroll';
import Logo from '../assets/MST-logo.png';
import { AiOutlineClose } from 'react-icons/ai';


const Navbar = () => {
    const [nav, setNav] = useState(false);
    const [shadow, setShadow] = useState(false);

    const handleClick = () => setNav(!nav);

    useEffect(() => {
        const handleShadow = () => {
            if (window.scrollY >= 90) {
                setShadow(true);
            } else {
                setShadow(false);
            }
        };
        window.addEventListener('scroll', handleShadow);
    }, []);

    return (
        <div className={
            shadow
                ? "shadow-xl ease-in-out duration-300 fixed w-full h-[80px] 7xl:h-[150px] flex justify-between items-center px-4 bg-[#F7FBFC] text-gray-700"
                : "fixed w-full h-[80px] 7xl:h-[150px] flex justify-between items-center px-4 7xl:px-4 bg-[#F7FBFC] text-gray-700"}>

            <div>
                <Link className="cursor-pointer" to="home" smooth={true} duration={500} >
                    <img className="w-[50px] h-[50px] 7xl:w-[120px] 7xl:h-[120px]" src={Logo} alt="Logo Image" />
                </Link>

            </div>
            
            {/* Menu */}

            <ul className="hidden 7xl:space-x-3 uppercase xl:flex">
                <li>
                    <Link to="home" className="7xl:text-4xl" smooth={true} duration={500} >
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="about" className="7xl:text-4xl" smooth={true} duration={500} >
                        About
                    </Link>
                </li>
                <li>
                    <Link to="skills" className="7xl:text-4xl" smooth={true} duration={500} >
                        Skills
                    </Link>
                </li>
                <li>
                    <Link to="work" className="7xl:text-4xl" smooth={true} duration={500} offset={-140}>
                        Work
                    </Link>
                </li>
                <li>
                    <Link to="contact" className="7xl:text-4xl" smooth={true} duration={500} offset={-50} >
                        Contact
                    </Link>
                </li>
            </ul>


            {/* Hamburger */}
            <div onClick={handleClick} className="xl:hidden z-10 cursor-pointer">
               <FaBars /> 
            </div>
            {/* Mobile menu */}
            <div className={nav ? "fixed left-0 top-0 w-full h-screen bg-black/70" : 'fixed left-0 top-0 w-full'}>
                <div className={nav 
                    ? "fixed rounded-r-xl left-0 top-0 w-[75%] sm:w-[55%] md:w-[45%] lg:w-[35%] xl:w-[25%] h-screen bg-[#ECF0F3] p-10 ease-in duration-500" 
                    : "fixed left-[-100%] top-0 p-10 ease-in duration-500"}>
                    <div>
                        <div className="flex w-full items-center justify-between">
                            <img src={Logo} alt="Logo Image" style={{ width: '60px' }} />
                            <div onClick={handleClick} className="rounded-full shadow-md shadow-gray-400 p-2 cursor-pointer">
                                <AiOutlineClose />
                            </div>
                        </div>
                        <div className="border-b border-gray-300 my-4 text-sm">
                            <p className="w-[85%] md:w-[90%] py-4">Let's build something together</p>
                        </div>
                    </div>
                    <div>
                        <ul className="bg-[#ECF0F3] flex flex-col uppercase">
                            <li className="mt-6 p-0">
                                <Link className="text-xl rounded p-2 hover:text-2xl hover:text-[#5651e5] duration-500" onClick={handleClick} to="home" smooth={true} duration={500} >
                                    Home
                                </Link>
                            </li>
                            <li className="mt-6 p-0">
                                <Link className="text-xl rounded p-2 hover:text-2xl hover:text-[#5651e5] duration-500" onClick={handleClick} offset={-120} to="about" smooth={true} duration={500} >
                                    About
                                </Link>
                            </li>
                            <li className="mt-6 p-0">
                                <Link className="text-xl rounded p-2 hover:text-2xl hover:text-[#5651e5] duration-500" onClick={handleClick} offset={-50} to="skills" smooth={true} duration={500} >
                                    Skills
                                </Link>
                            </li>
                            <li className="mt-6 p-0">
                                <Link className="text-xl rounded p-2 hover:text-2xl hover:text-[#5651e5] duration-500" onClick={handleClick} offset={-80} to="work" smooth={true} duration={500} >
                                    Work
                                </Link>
                            </li>
                            <li className="mt-6 p-0">
                                <Link className="text-xl rounded p-2 hover:text-2xl hover:text-[#5651e5] duration-500" onClick={handleClick} offset={-100} to="contact" smooth={true} duration={500} >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                        <div className="fixed pt-10">
                            <p className="uppercase  text-[#5651e5]">Let's Connect</p>
                            <div className="flex items-center justify-between my-4 gap-3 w-full sm:w-[80%]">
                                <a className="rounded-full shadow-md shadow-gray-400 p-2 cursor-pointer hover:scale-110" href="https://www.linkedin.com/in/malikst/">
                                    <FaLinkedin size={25} />
                                </a>
                                <a className="rounded-full shadow-md shadow-gray-400 p-2 cursor-pointer hover:scale-110" href="https://github.com/maliksheharyaar">
                                    <FaGithub size={25} />
                                </a>
                                <a className="rounded-full shadow-md shadow-gray-400 p-2 cursor-pointer hover:scale-110" href="mailto:malik.s.talhat@gmail.com">
                                    <HiOutlineMail size={25} />
                                </a>
                                <a className="rounded-full shadow-md shadow-gray-400 p-2 cursor-pointer hover:scale-110" href="/MST-2022-Resume.pdf" download>
                                    <BsFillPersonLinesFill size={25} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Socials */}
            <div className={!nav ? "hidden xl:flex fixed flex-col top-[35%] left-0" : "hidden"}>
                <ul>
                    <li className="w-[160px] h-[60px] 7xl:w-[310px] 7xl:h-[120px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] 7xl:ml-[-180px] duration-300 bg-blue-600 rounded-tr-lg 7xl:rounded-tr-3xl">
                        <a className="flex justify-between items-center w-full text-gray-300 7xl:text-4xl" href="https://www.linkedin.com/in/malikst/">
                            Linkedin <FaLinkedin className="w-[30px] h-[30px] 7xl:w-[80px] 7xl:h-[80px] 7xl:pr-4" />
                        </a>
                    </li>
                    <li className="w-[160px] h-[60px] 7xl:w-[310px] 7xl:h-[120px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] 7xl:ml-[-180px] duration-300 bg-[#333333]">
                        <a className="flex justify-between items-center w-full text-gray-300 7xl:text-4xl" href="https://github.com/maliksheharyaar">
                            Github <FaGithub className="w-[30px] h-[30px] 7xl:w-[80px] 7xl:h-[80px] 7xl:pr-4" />
                        </a>
                    </li>
                    <li className="w-[160px] h-[60px] 7xl:w-[310px] 7xl:h-[120px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] 7xl:ml-[-180px] duration-300 bg-[#242F9B]">
                        <a className="flex justify-between items-center w-full text-gray-300 7xl:text-4xl" href="mailto:malik.s.talhat@gmail.com">
                            Email <HiOutlineMail className="w-[30px] h-[30px] 7xl:w-[80px] 7xl:h-[80px] 7xl:pr-4" />
                        </a>
                    </li>
                    <li className="w-[160px] h-[60px] 7xl:w-[310px] 7xl:h-[120px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] 7xl:ml-[-180px] duration-300 bg-[#0D7377] rounded-br-lg 7xl:rounded-br-3xl">
                        <a className="flex justify-between items-center w-full text-gray-300 7xl:text-4xl" href="/MST-2022-Resume.pdf" download>
                            Resume <BsFillPersonLinesFill className="w-[30px] h-[30px] 7xl:w-[80px] 7xl:h-[80px] 7xl:pr-4" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar